<template>
  <section id="Malaatencion">
    <HeaderSection />
    <FormReclamo v-if="showForm" :callback="submit" department="Dirección de vialidad" :reclamos="[
      'Dañaron algo de mi propiedad',
      'No barrieron toda la nieve de mi vereda',
      'No respetan los horarios establecidos',
      'El barredor fue agresivo / maleducado'
    ]" />
    <Loading v-if="loading">{{loadingText}}</Loading>
    <div class="bg" v-if="!showForm && !loading">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-8 offset-md-2">
            <div class="mt-5 mb-5 pl-3 pr-3">
              <ArticleTitle>Tu reclamo ya está registrado</ArticleTitle>
              <SecondaryTitle>Acá podés encontrar la constancia de tu reclamo. Aconsejamos descargarlo para no perder el número. Si nos dejaste tu mail, nos vamos a contactar para compensar los daños.</SecondaryTitle>
            </div>
            <ReclamoSVG
              department="Dirección de vialidad"
              :street="street"
              :date="date"
              :name="name"
              :email="email"
              :detail="detail"
              class="svg-reclamo"
              ref="rec"
            />
            <div class="row">
              <div class="col-12">
                <Button @click="download">DESCARGAR</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterSection />
  </section>
</template>

<script>
import HeaderSection from '@/components/HeaderSection.vue'
import FormReclamo from '@/components/FormReclamo.vue'
import FooterSection from '@/components/FooterSection.vue'
import ReclamoSVG from '@/components/ReclamoSVG.vue'
import Loading from '@/components/Loading.vue'
import Button from '@/components/Button/Button.vue'
import ArticleTitle from '@/components/Text/ArticleTitle.vue'
import SecondaryTitle from '@/components/Text/SecondaryTitle.vue'

export default {
  name: 'Malaatencion',
  components: {
    HeaderSection,
    FormReclamo,
    FooterSection,
    ReclamoSVG,
    Button,
    Loading,
    ArticleTitle,
    SecondaryTitle
  },
  data () {
    return {
      showForm: true,
      street: '',
      date: '',
      name: '',
      email: '',
      detail: [],
      loadingText: '',
      loading: false
    }
  },
  methods: {
    submit (data) {
      this.street = data.place || ''
      this.date = data.date
      this.name = data.nombre || ''
      this.email = data.email || ''

      const reclamos = Object.keys(data.checkedReclamos)
      for (let index = 0; index < reclamos.length; index++) {
        if (data.checkedReclamos[reclamos[index]]) this.detail.push(reclamos[index])
      }

      window.scrollTo(0, 0)
      this.showForm = false
      this.loading = true

      setTimeout(() => (this.loadingText = 'Iniciando validación de datos...'), 2000)
      setTimeout(() => (this.loadingText = 'Consultando perfil tributario...'), 4000)
      setTimeout(() => (this.loadingText = 'Generando reclamo...'), 6000)
      setTimeout(() => (this.loading = false), 8000)

      if (!this.email) return

      fetch('https://mininvierno-service.javi.workers.dev/', {
        method: 'POST',
        body: JSON.stringify({
          name: this.name,
          email: this.email,
          model: 'RV'
        })
      })
    },
    goBack () {
      this.street = ''
      this.date = ''
      this.name = ''
      this.email = ''
      this.detail = []
      this.showForm = true
    },
    download () {
      const a = document.createElement('a')
      a.href = this.$refs.rec.getPNG()
      a.download = 'reclamo.png'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    }
  }
}
</script>

<style lang="scss" scoped>
#Malaatencion{
  overflow-x: hidden;
}
p{
  font-family: Fairview, sans-serif;
  font-size: 24px;
  line-height: 24px;
  color: #212151;
  padding: 30px;
}
.svg-reclamo{
  margin-bottom: 20px;
}
.bg{
  background-color: #E5E5E5;
  padding-bottom: 50px;;
}
</style>
